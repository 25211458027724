import React, { useEffect, useState } from "react";
import { FaBootstrap, FaHtml5, FaPhp, FaReact } from "react-icons/fa";
import { SiFirebase, SiJavascript, SiMysql } from "react-icons/si";
import { BsArrowUpRight } from "react-icons/bs";
import "./Projects.css";
import getImages from "../../services/getImages";
import config from "../../config";
import OwlCarousel from 'react-owl-carousel';

const Projects = () => {
  const [cursodonticImages, setCursodonticImages] = useState([]);
  const [promainImages, setPromainImages] = useState([]);
  const [divumImages, setDivumImages] = useState([]);
  const [dashboardEja, setDashboardEja] = useState([]);
  const [opinio, setOpinio] = useState([]);

  useEffect(() => {
    getAllImages();
  }, []);

  function getAllImages() {
    getImages().then((response) => {
      let _response = JSON.parse(response);
      console.log(_response.images.cursodontic);
      if(_response.response === 'success') {
        setCursodonticImages(_response.images.cursodontic);
        setPromainImages(_response.images.promain);
        setDivumImages(_response.images.divum);
        setDashboardEja(_response.images.dashboardEja);
        setOpinio(_response.images.opinio);
      }
    });
  }

  const state= {
    responsive:{
        0: {
            items: 1,
            center: true
        },
        576: {
            items: 1,
            center: true
        },
        768: {
            items: 1,
            center: true
        },
        992: {
            items: 2,
            center: true
        },
        1200: {
            items: 2,
            center: true
        },
    },
  }

  return (
    <div className="projects container-fluid">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 d-flex align-items-center" data-aos="fade-up" data-aos-duration="800">
            <h2 className="fs-1 mb-3 blue-color">Projects</h2>
            <span className="skills__subtitle-background">PROJECTS</span>
          </div>
          <div className="my-5">
            <div className="row">
              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">CURSODONTIC</h3>
                  </div>
                  <p>
                    The website is centered around an advanced e-learning platform that offers a comprehensive range of dental courses. With a formidable presence across Latin America and other Spanish-speaking regions around the world, the platform is tailored to meet the diverse learning needs of dental professionals.
                  </p>
                  <div className="d-flex">
                    <a
                      href="https://cursodontic.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies" >
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1600">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiFirebase className="black-color" />
                      <span className="tooltiptext mt-1">Firebase</span>
                    </div>
                  </div>
                </div>
                <div className="py-4">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                    // data-aos="fade" data-aos-duration="2000"
                  >
                    {cursodonticImages?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={config.urlApi+image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>
              
              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">PROMAIN INDUSTRIAL</h3>
                  </div>
                  <p>
                    For this project I designed and developed the website's UI/UX, with the primary objective of generating leads and attracting distributors. The website effectively showcases the products and services, providing a comprehensive overview that is sure to resonate with potential clients.
                  </p>
                  <div className="d-flex">
                    <a
                      href="http://promainindustrial.com.mx/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="py-4">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {promainImages?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={config.urlApi+image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>

              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">DIVUM CLINIC</h3>
                  </div>
                  <p>
                    As part of the website development project, I have successfully integrated a blog along with a versatile module for seamless post uploads. With this module, users can effortlessly customize their posts by incorporating images, and have the flexibility to edit or delete their posts as per their preference.
                  </p>
                  <div className="d-flex">
                    <a
                      href="https://divum.vercel.app/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="py-4">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {divumImages?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={config.urlApi+image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>
              
              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">DASHBOARD EJA</h3>
                  </div>
                  <p className="fw-light fs-6">By: Enlace Jurídico Académico</p>
                  <p>
                    The primary function of this dashboard is to provide accurate and important data on website usage metrics. By handling the dashboard, administrators can effectively track the number of visitors to each website they own.
                  </p>
                  <div className="d-flex">
                    <a
                      href="http://dashboard.enlacejuridicoacademico.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <FaReact className="black-color" />
                      <span className="tooltiptext mt-1">ReactJS</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="py-4">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {dashboardEja?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={config.urlApi+image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>

              <>
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 projects__description">
                  <div data-aos="fade-right" data-aos-duration="1000">
                    <h3 className="fw-bold fs-1 mb-3">REVISTA OPINIO IURIS</h3>
                  </div>
                  <p className="fw-light fs-6">By: Enlace Jurídico Académico</p>
                  <p>
                    For this project I designed and developed the website's UI/UX, with the primary objective of share academic articles. 
                  </p>
                  <div className="d-flex">
                    <a
                      href="revistaopinioiuris.enlacejuridicoacademico.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        Visit <BsArrowUpRight />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 projects__technologies">
                  <h5 className="fw-bold mb-0">Technologies</h5>
                  <div className="projects__techIcons">
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1600">
                      <FaHtml5 className="black-color" />
                      <span className="tooltiptext mt-1">HTML5</span>
                    </div>
                    {/* <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <FaCss3Alt className="black-color" />
                      <span className="tooltiptext mt-1">CSS</span>
                    </div> */}
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1400">
                      <SiJavascript className="black-color" />
                      <span className="tooltiptext mt-1">JavaScript</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1200">
                      <FaBootstrap className="black-color" />
                      <span className="tooltiptext mt-1">Bootstrap</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="1000">
                      <FaPhp className="black-color" />
                      <span className="tooltiptext mt-1">PHP</span>
                    </div>
                    <div className="tooltop" data-aos="fade-up" data-aos-duration="800">
                      <SiMysql className="black-color" />
                      <span className="tooltiptext mt-1">MySQL</span>
                    </div>
                  </div>
                </div>
                <div className="py-4">
                  <OwlCarousel
                    className="owl-theme industry__owl"
                    items="2"
                    autoplay={true}
                    loop
                    animateIn={true}
                    responsive={state.responsive}
                    center={true}
                    animateOut={'fadeOut'}
                    autoplayHoverPause={true}
                    margin={15}
                  >
                    {opinio?.map(image => (
                      <div key={image.alt_tag+image.id_imageProject} className="pt-4">
                        <img
                          src={config.urlApi+image.image_url}
                          className="d-block w-100 shadow"
                          alt={image.alt_tag}
                        />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
